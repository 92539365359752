<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

defineProps<{
  loading: boolean
  count: number
}>()

const emit = defineEmits<{
  (event: 'action' | 'close'): void
}>()

const { t } = useI18n()
</script>

<template>
  <base-dialog
    data-cy="batchDeleteDialog"
    max-width="632"
    :title="t('global.delete.batch_dialog.title', count)"
    :disabled="loading"
    :loading="loading"
    :action-text="t('global.delete.delete')"
    action-color="error"
    :cancel-text="t('global.delete.batch_dialog.cancel', count)"
    @action="emit('action')"
    @close="emit('close')"
  >
    {{ t('global.delete.batch_dialog.subtitle') }}
  </base-dialog>
</template>
